<template>
  <div class="container">
    <div class="section-header">
      <h1
        class="section-title titleLegal fadeInRight"
        style="text-transform: none"
        data-wow-duration="1000ms"
        data-wow-delay="100ms"
      >
        Support technique
      </h1>
    </div>
    <div class="rowlegal">
      <p>
        Adresser toute demande de support technique par courriel à l'adresse : <a href="mailto:technique@linaware.eu">technique@linaware.eu</a>
      </p>
      <p>
        Pour toute autre information d'ordre commercial, consulter le site web de Linaware <a href="https://www.linaware.eu" target="_blank">en cliquant ici</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "mentions",
  components: {},

  methods: {},
};
</script>

<style lang="scss">
@media only screen and (max-width: 1200px) {
  .section-header {
    max-width: 800px;
  }
}

.rowlegal {
  margin-right: -15px;
  margin-left: -15px;
}

.section-header {
  text-align: center;
  position: relative;
  margin-bottom: 3.6rem;
  max-width: 960px;
  margin: auto;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.titleLegal {
  font-family: "montserrat-medium", sans-serif;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  margin-top: 6rem;
  margin-bottom: 1.8rem;
  font-size: 3.6rem;
  line-height: 1.25;
  letter-spacing: -0.1rem;
}

p {
  margin-bottom: 3rem;
}

p {
  margin: 0 0 10px;
}

.subtitleLegal {
  font-size: 2.4rem;
  line-height: 1.25;
}
</style>
